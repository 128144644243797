import { configBuilder } from "ccui-framework";
import {
  commonConfig,
  largeText,
  smallText,
} from "../../../src/config/partner";

const Config = {
  uber: {
    ...commonConfig,
    layoutVersion: 1,
    cvvTextPlaceHolder: "CCV Number",
    // Verification
    verificationType: "tlo",
    reduxLogger: false,
    footerLogoImage:
      "https://cdn.cardcash.com/website/partners/common/card_cash_logo.png",
    footerSocialClass: "box",
    footerCopyright: {
      text: () => "Uber Technologies Inc.",
    },
    partnerBrand: "Uber",
    partnerURL: "http://www.uber.com/",
    partnerEmail: "uber-support@cardcash.com",
    giftCardTerms: "https://www.uber.com/legal/gift-cards/en-ca/",
    paymentType: "eGift Card",
    aOrAn: "an",
    facebookURL: "https://www.facebook.com/uber/",
    twitterURL: "https://twitter.com/uber",
    youTubeURL: "https://www.youtube.com/channel/UCgnxoUwDmmyzeigmmcf0hZA",
    instagramURL: "https://www.instagram.com/uber/?hl=en",
    googleURL: "https://plus.google.com/112684473482252498171",
    linkedInURL: "https://www.linkedin.com/company/uber-com",
    pinterestURL: "https://www.pinterest.com/uber/",
    googleAnalytics: "UA-71687019-1",
    x_cc_app: "I7q4sjLEw",
    baseURL: "https://production-api.cardcash.com/",
    iconThreeText: "Receive an eGift Card",
    showTermsConditions: true,
    headerLogoBrand:
      "https://cdn.cardcash.com/website/partners/uber/uber-logo.png",
    headerLogoCC:
      "https://cdn.cardcash.com/website/partners/common/cc_logo_dark.png",
    headerLogoBrandIcon:
      "https://cdn.cardcash.com/website/partners/uber/uber-logo.png",
    headerLogoCCIcon:
      "https://cdn.cardcash.com/website/partners/common/cc_logo_dark.png",
    headerMenuItemImage1: null,
    headerMenuItemImage2: null,
    headerMenuItemImage3: null,

    headerTextPartOne: "Exchange Gift Cards for an",
    headerTextPartTwo: " Uber eGift Card",

    bottomLinkText: "Redeem your eGift Card at",
    headerContactLinkText: "Gift Card Help",
    sellBtnText: "GET MY EGIFT CARD",
    offerButtonTxt: "GET OFFER",
    totalText: "Total Gift Card Offer",

    // Use multiline strings
    stepsVersion: 2,
    stepsHideBottomOn: [],
    detailStepOneText: {
      description: [
        largeText("Exchange Gift Cards"),
        largeText("for an Uber gift Card"),
        smallText("Over 200 Merchants Accepted"),
      ],
      header: "Get Offer",
      text: "Enter merchant name and card balance for a quick exchange offer. In seconds, see how much you can get in exchange for your gift card.",
    },
    detailStepTwoText: {
      description: [largeText("Enter Your Card"), largeText("and Pin Numbers")],
      header: "Enter Information",
      text: "Enter card number and pin and some basic customer information. Once your order is placed, your card balance will be verified behind the scenes to ensure the fastest and most secure process possible.",
    },
    detailStepThreeText: {
      description: [largeText("Enter Your Personal Information")],
      header: "Receive your Uber Gift Card",
      iconText: "Receive an eGift Card",
      cardImage:
        "https://cdn.cardcash.com/website/partners/uber/uber-digital-card.png",
      text: "Your gift card will be emailed to you once your order has been approved. Approval time can take up to 1 business day.",
    },
    cardCashLogoFirst: true,
  },

  "test-uber": {
    googleAnalytics: "",
    x_cc_app: "L7q4FjLEa",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },

  localhost: {
    googleAnalytics: "",
    x_cc_app: "L7q4FjLEa",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("uber", Config);
